@import '../../styles/colors.scss';

.promptBox {
    border-left: 5px solid $color-motokiki-logo-blue;
    background-color: #f5f5f9;
    padding:30px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  
    h2 { 
        width: 100%
    }
}

@media screen and (max-width:480px){ 
    .promptBox {
        width:100%;
    }
}
