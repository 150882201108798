
.fitterCardArray {
    display: flex;
    flex-direction: row;
    justify-content: flex start;
    align-items: center;
    overflow-x: auto;

    .fitterCard {
        min-width: 200px;
        margin: 10px;
    }
}

@media only screen and (min-width: 769px) {
    .fitterCardArray {
        flex-direction: column;
        overflow-x: unset;
        overflow-y: auto;
        height: 140vh;
    }

    .title {
        text-align: center;
    }
}