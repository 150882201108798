@import '../../styles/colors.scss';

.container {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  max-width: 1200px;
  margin: auto
}

.layoutFlex {
  display: flex;
  flex-direction: column;
}

.show {
  display: block
}

.hide {
  display: none
}

.smaller {
  max-width: 250px;
}

.stepsContent {
  padding-top: 40px
}

.uploadIcon {
  font-size: 48px;
  padding-bottom: 20px;
  color: $primary-color;
}

.tooltipLabelBox {
  display: flex;

  .tooltipLabel {
      margin-right: 20px;
      margin-bottom: 5px;
  }
}
.activeFitterTooltip {
  padding-top: 6px
}

.tabTitle {
  margin-top: 30px;;
}

.tabTops {
  display: flex;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }

  .activeTab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid grey;
    padding: 10px 20px 10px 30px;
    background-color: $color-motokiki-logo-red;
    color: white;
    font-weight: 700;
    margin: 10px;
    border-radius: 30px;
    box-shadow: 2px 5px $color-motokiki-logo-blue;


    p {
      padding: 0;
      margin: 0;
    }
  }

  .tab {
    border: 1px solid $light-grey;
    padding: 10px  0 10px 30px;
    background-color: $color-motokiki-logo-blue;
    font-weight: 700;
    color: white;
    margin: 10px;
    border-radius: 30px;

    &:hover{
      opacity: 0.8;
    }

    p {
      padding: 0;
      margin: 0;
    }
  }
}

.fitterButton {
  margin: 20px;
  display: flex;
  align-items: center;

  div {
    margin-left: 5px
  }
}

.modalContent {
  background-color: #dfdfdf;
  padding: 30px;
  color: #637684;

  .modalButtons {
    div {
      margin: 15px;
    }
  }
}

.stepButtons {
  display: none;
}

.stepButtonsTablet {
  display: none;
}

@media only screen and (min-width: 460px) {
  .stepButtons {
    display: none
  }

  .stepButtonsMobile {
    display: none
  }

  .wheelSizeFlexTablet {
    display: flex;
    justify-content: space-between
  }

  .stepButtonsTablet {
    display: unset;
    margin-top: 15px;
    align-self: center;
    width: 200px;
  }

  .buttonFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .tabTops {
    flex-direction: row;

    .activeTab {
      width: 33%;
    }

    .tab {
      width: 33%;
    }
  }

  .tabContent {
    // height: 50vh;
    // overflow-y: auto;
  }
}

@media only screen and (min-width: 769px) {
  .layoutFlex {
    flex-direction: row-reverse;
    justify-content: space-around;
  }

  .stepButtons {
    display: unset
  }

  .stepButtonsMobile {
    display: none;
  }

  .stepButtonsTablet {
    display: none
  }

  .emptyFitterSidebar {
    height: 165vh;
  }

  .fitterSidebar {
    height: calc(100% - 10%);
  }
  .sidebarContainer {
    margin-left: 30px
  }

  .tabContent {
    width: 65vw;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .modalButtons {
      display: flex;
    }
  }
}