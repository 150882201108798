$primary-color: #d84200; // primary color for all components
$link-color: #d84200; // link color
$success-color: #5fa90e; // success state color
$success-light-color: #87d068; // success light state color
$warning-color: #ee7700; // warning state color
$error-color: #ee7700; // error state color
$font-size-base: 14px; // major text font size
$heading-color: #282828;// heading text color
$text-color: #484645; // major text color
$disabled-color : rgba(0, 0, 0, .25); // disable state color
$border-radius-base: 4px; // major border radius
$border-color-base: #d9d9d9; // major border color
$box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers
$card-header-background: #EFF0F8;
$card-header-border: #e5e8f1;
$card-list-border: #E5E8F2;
$black: #000000;
$white: #FFFFFF;
$grey-blue-form:#4e5b63;
$lighter-blue-grey: #637684;
$transparent: rgba(0,0,0,0);
$almost-transparent: rgba(0,0,0,0.1);
$off-white: #f0f0f0;
$primary-active-color: #b33700;
$primary-color-light: rgba(238, 119, 0, 0.8);
$primary-dark-color: #810100;
$red-error: #D0021B;
$input-grey: #999999;
$secondary-color: #484645;
$secondary-active-color: #282828;
$tertiary-hover-color: rgba(238, 119, 0, 0.08);
$footer-base: #181818;
$footer-links: #d5d5d5;
$dark-grey: #a6a6a6;
$darker-grey: #333;
$light-grey: #dedede;
$lighter-grey: #f5f5f9;
$shadow: #999;
$white: #fff;
$black: #000;
$success-color: #5fa90e;
$success-active-color: #467f07;
$success-very-light: rgba(95, 169, 14, 0.2);
$error-color: #b30000;
$text-color: #282828;
$selected-text-color: #282828;
$disabled-text-color: #a6a6a6;
$fade-grey: #f5f5f5;
$mineshaft: #333;
$black-haze: #f6f6f6;
$fuscous-grey: #555;
$shadow-grey:#b2b2c0;
$background-grey:#f1f1f7;
$hover-grey:#f8f9fa;
$faint-grey:#b8b8b8;
$success-opaque-color: rgba(134, 208, 103, 0.1);
$success-clear-color: rgba(134, 208, 103, 0.05);
$primary-opaque-color:rgba(240, 120, 0, 0.1);
$primary-clear-color: rgba(240, 120, 0, 0.05);
$deep-purple: #6e3d99;
$purple-opaque:rgba(225, 211, 238, 0.25);
$purple-clear: rgba(225, 211, 238, 0.20);
$error-opaque: rgba(179, 0, 0, 0.1);
$error-clear: rgba(179, 0, 0, 0.05);
$open-water-deep: rgba(24, 78, 127, 1);
$open-water: rgba(72, 120, 168, 1);
$open-water-opaque: rgba(72, 120, 168, 0.1);
$open-water-clear: rgba(72, 120, 168, 0.05);
$tropical-sky: rgba(168, 216, 240, 1);
$tropical-sky-opaque: rgba(168, 216, 240, 0.1);
$tropical-sky-clear: rgba(168, 216, 240, 0.05);
// $brown-sauce: rgba(120, 24, 75, 0.9);
// $brown-sauce: #EE6352;
$brown-sauce: #963484;
$brown-sauce: #39304A;
$brown-sauce: rgba(57, 48, 74, 0.8);
$brown-sauce: #7E7096;
$brown-sauce-opaque: rgba(120, 24, 0, 0.1);
$brown-sauce-clear: rgba(120, 24, 0, 0.05);
$atlantean: rgba(72, 120, 144, 1);
$atlantean-opaque: rgba(72, 120, 144, 0.1);
$atlantean-clear: rgba(72, 120, 144, 0.05);
$midori: rgba(120, 168, 72, 0.85);
$midori-opaque: rgba(120, 168, 72, 0.1);
$midori-clear: rgba(120, 168, 72, 0.05);
$pitch: rgba(24, 96, 48, 1);
$pitch-opaque: rgba(24, 96, 48, 0.1);
$pitch-clear: rgba(24, 96, 48, 0.05);

//rebranding colors
$color-motokiki-logo-red: #9D1E4B;
$color-motokiki-logo-blue: #11244C;

/* Motokiki rebrand text color*/
$color-text-red: #A4164A;
$color-text-red-hover: #8b1643;
$color-text-blue: #14244B;
$color-text-yellow: #FFCD17;
$color-light-grey: #e6e7e8;
$color-dark-grey: #3e444e;

/*Shadow effects*/
$lightShadow: rgba(0,0,0,0.2)