@import '../../styles/colors.scss';

.breadcrumb {
    display: flex;

    p {
        margin: 5px;
        font-size: 15px;;
    }

    .activeStep {
        color: $color-motokiki-logo-red
    }

    .completeStep {
        color: $dark-grey
    }
}

