.FitterCard {
    width: 100%;
    margin: 2px;
    padding: 5px;
    background-color: rgb(223, 223, 223);
    
    &:hover {
        cursor: pointer;
    }

    .fitterCardButton {
        width: 60%;
        
    }
}


