@import '../../styles/colors.scss';

.stapletonsBody{
  background-color: white;
  padding: 30px 20px;
  max-width: 991px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .idForm{
    width: 60%;
    @media screen and (max-width:480px){
      width:100%;

      .button{
        width:100%;
        max-width:400px
      };
    }
  }

  .additionalInfo{
    color: white;
    border-top: 5px solid #637684;
    background-color: #f5f5f9;
    padding:30px;
    width: 35%;
    display: flex;
    flex-wrap: wrap;

    h3{
      width: 100%;
    }
    
    a{
      color: #637684;
      width: 100%;
    }

    @media screen and (max-width:480px){
      width:100%
    }
  }
}