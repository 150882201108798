@import '../../styles/colors.scss';

.containerShell {
    padding: 0 20px 20px 20px
}

.mainBanner {
    background-image: url(../../assets/auto-automobile-automotive-164643-1-cropped.jpg);
    background-color: white;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 25vh;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    h2 {
        color: white;
        text-align: center;
        font-size: 20px;
        text-shadow: 2px 2px 8px black;
    }
    
    h3 {
        color: white;
        text-align: center;
        font-size: 15px;
        text-shadow: 2px 2px 8px black;
    }
}

.scrollBox {
    border: 2px solid $grey-blue-form;
    background-color: $fade-grey;
    padding: 10px;
    margin-bottom: 20px;
    height: 25vh;

    .scrollBoxContent {
        overflow-y: auto;
        height: 100%;
    }

    .highlightedCondition {
        color: $color-motokiki-logo-red
    }
}

.link {
    color: $color-motokiki-logo-red;
}

.acceptButtons {
    border: 2px solid $grey-blue-form;
    background-color: $fade-grey;
    padding: 10px 10px 15px 10px;
    text-align: center;

    .buttonFlex {
        // border: 2px solid black;
        display: flex;
        justify-content: center;
        align-items: center;

        .buttonBox {
            width: 200px;

            .continueButton {
                margin-top: 10px;
            }
        }
    }
}

.charterText {
    h3 {
        text-align: center;
    }

    h4 {
        text-align: center;
    }

    .threeValues {
        margin: 40px 0 40px 0;
    }
}

@media only screen and (min-width: 426px) {
    .threeValues {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .acceptButtons {
        .buttonBox {
            .continueButton {
                margin: 0 0 0 30px;
            }
        }
    }
}

@media only screen and (min-width: 769px) {
    .mainBanner {
        h2 {
            font-size: 40px;
        }

        h3 {
            font-size: 30px;
        }
    }
}

@media only screen and (max-width: 450px) {
    .acceptButtons {
        .buttonFlex{
            flex-direction: column;
        }
    }
}
