@import '../../styles/colors.scss';

.navLocationContainer{
  background-color: $color-motokiki-logo-blue;
  color: $white;
  font-size: 20px;
  padding: 20px 40px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  .navLocation{
    display: flex;
    width: 100%;
    max-width: 1071px;
  }


  .location {
    padding: 0;
    margin: 0;
  }
}
@media only screen and (max-width: 480px) {
  .breadcrumb {
    display: none;
  }
}


@media only screen and (min-width: 500px) {
  .navLocation {
    flex-direction: row;
    justify-content: space-between;
  }
  
}

@media only screen and (min-width: 1029px) {
  .navLocation {
    padding: 0px 40px;
  }
}

@media screen and (max-width: 755px) {
  .navLocationContainer {
    top: 137px;
  }
}
