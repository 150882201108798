@import '../../styles/colors.scss';

.container {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  max-width: 1200px;
  margin: auto
}

.btnCont{
  display: flex;
  justify-content: center;
}

.show {
  display: block
}

.hide {
  display: none
}

.smaller {
  max-width: 250px;
}

.stepsContent {
  padding-top: 40px
}

.uploadIcon {
  font-size: 48px;
  padding-bottom: 20px;
  color: $primary-color;
}

.tooltipLabelBox {
  display: flex;

  .tooltipLabel {
      margin-right: 20px;
      margin-bottom: 5px;
  }
}

.fitterOptionsCont{
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 420px) {
  .stepsAction {
    display: flex;
    justify-content: space-around;
    margin: 0 -5px;
  
    .button {
      margin: 0 5px;
    }
  }

  .buttonFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
  }
}