@import '../../styles/colors.scss';

.container {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  max-width: 1200px;
  margin: auto
}

.layoutFlex {
  display: flex;
  flex-direction: column;
}

.show {
  display: block
}

.hide {
  display: none
}

.smaller {
  max-width: 250px;
}

.stepsContent {
  padding-top: 40px
}

.uploadIcon {
  font-size: 48px;
  padding-bottom: 20px;
  color: $color-motokiki-logo-red;
}

.tooltipLabelBox {
  display: flex;

  .tooltipLabel {
      margin-right: 20px;
      margin-bottom: 5px;
  }
}
.activeFitterTooltip {
  padding-top: 6px
}

.tabTitle {
  margin-top: 30px;;
}

.tabTops {
  display: flex;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }

  .activeTab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid grey;
    padding: 10px 20px 10px 30px;
    background-color: $color-motokiki-logo-red;
    color: white;
    font-weight: 700;
    margin: 10px;
    border-radius: 30px;
    box-shadow: 2px 5px $color-motokiki-logo-blue;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .tab {
    border: 1px solid $light-grey;
    padding: 10px  0 10px 30px;
    background-color: $color-motokiki-logo-blue;
    font-weight: 700;
    color: white;
    margin: 10px;
    border-radius: 30px;    

    &:hover{
      opacity: 0.8;
    }


    p {
      padding: 0;
      margin: 0;
    }
  }
}

.fitterButton {
  margin: 20px;
  display: flex;
  align-items: center;

  div {
    margin-left: 5px
  }
}

.stepsAction {
  margin-left: 20px
}

@media only screen and (min-width: 427px) {
  .stepsAction {
    display: flex;
    justify-content: space-around;
    margin: 0 -5px;
  
    .button {
      margin: 0 5px;
    }
  }

  .buttonFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .tabTops {
    flex-direction: row;

    .activeTab {
      width: 33%;
    }

    .tab {
      width: 33%;
    }
  }
}

@media only screen and (min-width: 769px) {
  .layoutFlex {
    flex-direction: row-reverse;
    justify-content: space-around;
  }

  .sidebarContainer {
    margin-left: 30px
  }

  .tabContent {
    width: 65vw;
  }
}