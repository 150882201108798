@import '../../styles//colors.scss';

.FitterCardBack {
    background-color: #dfdfdf;
    padding: 30px;

    span {
        color: $color-motokiki-logo-blue;
        font-weight: 700;
    }
}