@import "../../styles/colors.scss";

.headerBar {
  width: 100%;
  margin: auto;
  background: $white;

  position: sticky;
  top: 0;
  left: 0;
  z-index: 20;

  .header {
    width: 100%;
    max-width: 1071px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.mobile {
  height: 137px;
  position: sticky;
  top: 0;
  z-index: 20;
  flex: 0 0 0;
}

.motokikiLogo {
  height: 33px !important
}

.desktop {
  display: none;
  flex: 0 0 0;
}

.mobileTopLine {
  padding: 16px 40px 10px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mobileNav {
  background-color: $white;
}

.mainContent {
  min-height: 82vh;
}

@media only screen and (min-width: 769px) {
  .desktop {
    display: unset;
  }

  .mainContentWrapper {
    min-height: calc(100vh - 174px);
    flex: 1 0 0;
  }

  .mobile {
    display: none;
  }
}