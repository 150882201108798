@import './styles/colors.scss';
// @import './styles/fonts.less';

// * {
//   font-family: $number-font-family
// }

// .number{font-family: $number-font-family}
// input, .ant-calendar-picker-container *, .ant-pagination *, .ant-table *, .table * {font-family: $number-font-family}

#root {
  & > div {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

.ant-layout {
  background: $white;
}

body {
  color: $secondary-active-color;
}

.ant-layout-header {
  height: 64px;
  padding: 0 40px;
}

h1,
h2,
h3 {
  font-weight: 700;
}

.mk-center {
  text-align: center;

  button,
  div {
    margin: 0 auto;
  }
}

.svg_overlay_container {
  position: relative;

  .svg_overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    opacity: 0;
    transition: all 0.2s;
    transform: scale(0.1);
    transform-origin: 50% 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .svg_overlay__icon {
      font-size: 36px;
      cursor: pointer;
    }
  }

  &:hover {
    .svg_overlay {
      opacity: 1;
      transform: scale(1);
    }

    img {
      opacity: 0.3;
    }
  }
}

/*---------antd styles-----------*/

.anticon-file-pdf {
  font-size: 26px;
  margin: 0 5px 0 10px;
}

.ant-menu-horizontal {
  > .ant-menu-item {
    border-bottom: 3px solid $color-motokiki-logo-blue;

    a:hover {
      color: $color-motokiki-logo-red;
      font-weight: bold;
      margin: 0 -4px;
    }

    &:hover {
      border-bottom: 3px solid $color-motokiki-logo-red;
    }
  }

  > .ant-menu-item-active {
    border-bottom: 3px solid $color-motokiki-logo-red;
  }

  > .ant-menu-item-selected {
    color: $color-motokiki-logo-blue;

    > a {
      color: $color-motokiki-logo-red;
    }
  }
}

.ant-menu-item:active,
.ant-menu-submenu-title:active,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: fade-out($color-motokiki-logo-blue, 0.9);
}

.ant-menu-horizontal > .ant-menu-item-active {
  border-bottom: 3px solid $color-motokiki-logo-red;
}

.ant-switch {
  transform-origin: 0 0;
  transform: scale(1.3);
}

.ant-switch-checked {
  background-color: $color-motokiki-logo-blue;
}

.ant-btn-primary {
  background-color: $color-motokiki-logo-blue;
  border-color: $color-motokiki-logo-blue;

  &:active,
  &.active,
  &:focus {
    background-color: $color-motokiki-logo-red;
    border-color: $color-motokiki-logo-red;
  }

  &:hover {
    background-color: $color-motokiki-logo-blue;
    border-color: $color-motokiki-logo-red;
  }
}

.ant-btn-secondary {
  &:active,
  &.active,
  &:focus,
  &:hover {
    color: $color-motokiki-logo-red;
    border-color: $color-motokiki-logo-red;
  }
}

.ant-btn-mk,
.ant-btn-submit {
  background-color: $color-motokiki-logo-red;
  color: #fff;

  &:hover {
    background-color: lighten($color-motokiki-logo-red, 5%);
    color: #fff;
    border-color: lighten($color-motokiki-logo-red, 5%);
  }

  &:active,
  &.active,
  &:focus {
    background-color: lighten($color-motokiki-logo-red, 10%);
    color: #fff;
    border-color: #fff;
  }
}

.checkbox {
  .ant-checkbox {
    transform: scale(1.3);

    .ant-checkbox-inner {
      border-color: $color-motokiki-logo-blue;

      &:hover {
        border-color: $color-motokiki-logo-blue !important;
      }
    }
  }

  .ant-checkbox-checked {
    transform: scale(1.3);

    .ant-checkbox-inner {
      border-color: $color-motokiki-logo-blue;
      background-color: $color-motokiki-logo-blue;
    }

    &::after {
      border-color: $color-motokiki-logo-blue;
    }
  }
}

@media screen and (max-width: 961px) {
  .ant-menu-item-active {
    color: $color-motokiki-logo-blue;

    > a:hover {
      color: $color-motokiki-logo-blue;
    }
  }

  .ant-menu-item-selected {
    > a {
      color: $color-motokiki-logo-blue;

      &:hover {
        color: $color-motokiki-logo-blue;
      }
    }
  }

  .ant-menu-submenu-title .anticon {
    color: #000;

    &:hover {
      color: $color-motokiki-logo-blue;
    }
  }

  .ant-menu-submenu-inline {
    .ant-menu-item::after {
      border-color: $color-motokiki-logo-blue;
    }

    .ant-menu-submenu-title:hover {
      .ant-menu-submenu-arrow {
        &::before,
        &::after {
          background: linear-gradient(
            to right,
            $color-motokiki-logo-blue,
            $color-motokiki-logo-blue
          );
        }
      }
    }
  }

  .ant-menu-submenu-title:hover {
    color: $color-motokiki-logo-blue;
  }
}
