@import "../../styles/colors.scss";

.populatedMenu {
  line-height: 64px;
  border-bottom: none;

  .userPop { height: 63px}
}

.mobilenavPadding{
  padding-left: 16px;
}

.bareMenu {
  line-height: 64px;
}

.nonBurger {
  display: none;
}

@media only screen and (min-width: 769px) {
  .burger {
    display: none;
  }

  .nonBurger {
    display: unset;
  }
}