@import '~@motokiki/shared/styles/colors';
@import '~@motokiki/shared/styles/fonts';
@import '~@motokiki/shared/styles/responsive-breakpoints';

footer { 
  width: 100%;
  height: 110px;
}

.mk-footer {
  background-color: $color-motokiki-black;

  * {
    font-family: $font-family;
  }

  &-social {
    &__container {
      padding: 0 3rem 1rem 3rem;
    }

    &__links,
    &__logos {
      @media screen and (min-width: $new-desktop) {
        height: 2.75rem;
      }

      margin: 1rem 0;
    }

    &__logos {
      img {
        height: 2.75rem;
        margin: 0 1rem;

        @media screen and (max-width: $new-tablet - 1px) {
          margin: 0.5rem 1rem;
        }
      }
    }
    
    &__links{
      @media screen and (min-width: $new-desktop) {
        height: 2.75rem;

        a {
          max-width: 100%;
          background-color: $transparent !important;
          font-weight: 700;
        }
      }
    }
  }

  &-policies {
    &__container {
      background-color: $color-black;
      padding-top: 1rem;
    }

    &__legal {
      color: $color-white;
      text-align: center;
      padding: 0 1rem;

      @media screen and (max-width: $new-tablet - 1px) {
        margin: 1rem 0;
      }
    }

    &__links {
      margin: 1.25rem 0 2rem;
      padding: 0 1rem;
      color: $color-white;

      @media screen and (max-width: $new-desktop - 1px) {
        text-align: center;
      }

      @media screen and (max-width: $new-tablet - 1px) {
        margin: 0;
      }
    }

    &__link {
      a {
        color: $color-white;
      }
    }
  }

  &-links {
    &__container {
      margin-top: 2rem;
    }

    &__section-header {
      height: 2.75rem;
      min-width: 190px;

      button {
        color: $color-white;
        background: none;
        border: none;
        padding: 0;
      }
    }

    &__link {
      color: $color-motokiki-orange;
      padding: 0.5rem 0;
    }
  }

  &-simple {
    @media screen and (min-width: 1008px) {
      &__container {
        margin: auto;
      }
    }

    &-logos {
      &__container {
        margin-left: auto;
        margin-right: auto;
        max-width: 1024px;
        padding: 0 16px;
      }

      &__logo {
        p {
          font-weight: 400;
          color: $color-white;
        }

        img {
          height: 28px;
        }
      }
    }

    &-links {
      &__container {
        margin-left: auto;
        margin-right: auto;
        max-width: 1024px;
        padding: 0 16px;
      }

      &__link {
        color: $color-white;

        &:hover {
          color: $color-white;
          text-decoration: underline;
        }
      }
    }

    &-policies{
      &__container {
        margin-left: auto;
        margin-right: auto;
        max-width: 1024px;
        padding: 0 16px;
      }
      
      &__text {
        color: $color-white;
      }
    }
  }
}