.container {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  max-width: 1200px;
  margin: auto;
}

.mainContent {
  display: flex;
  flex-direction: column;

  .tooltipLabelBox {
    display: flex;

    .tooltipLabel {
      margin-right: 20px;
      margin-bottom: 5px;
    }
  }

  .fitterOptionsCont {
    .fitterOption {
      display: flex;
      margin: 15px 0;

      p {
        order: 2;
        margin-left: 26px;
      }

      button {
        order: 1;
      }
    }
  }

  .smaller {
    max-width: 250px;
  }

  .labelTitle {
    font-weight: 700;
    margin-top: 30px;
  }

  .antUploadText {
    font-weight: 400;
  }

  .nonForm {
    .linkButtonContainer {
      margin: 20px 0 20px 0;

      button {
        line-height: 36px;
        height: 40px;

        svg {
          position: relative;
          top: -2px;
        }
      }

      .linkButton {
        color: white;
      }
    }

    .imageCont {
      display: flex;
      justify-content: center;
    }

    .logoImage {
      width: 125px;
    }
  }
}

@media only screen and (min-width: 770px) {
  .mainContent {
    display: unset;

    .nonForm {
      div {
        width: 100%;
        display: flex;
      }

      .linkButtonContainer {
        margin: 5px;
        width: 33%;

        button {
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .mainContent {
    .nonForm {
      .logoImage {
        width: 200px;
      }
    }
  }
}