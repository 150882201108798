@import '~@motokiki/shared/styles/colors';
@import '~@motokiki/shared/styles/fonts';
@import '~@motokiki/shared/styles/responsive-breakpoints';

.footer2 {
    flex-shrink: 0;
    background-color: $color-motokiki-black;
    color: #fff;

    &__content-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        hr {
            border-top-width: 0;
            width: 100%;
            flex-shrink: 0;
        }

        &__company {
            padding: 10px;
            text-align: center;
        }

        &__link-box {

            ul {
                display: flex;
                flex-wrap: wrap;
                flex-grow: 1;
                justify-content: space-around;
                align-items: center;
                list-style-type: none;
                margin-bottom: 0;
                padding-left: 0;

                li {
                    padding: 10px;

                    a {
                        line-height: 1em;
                        color: #fff;

                        svg {
                            width: auto;
                            height: 1em;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 747px) {
            &__company {
                order: 3;
            }

            hr {
                order: 2;
            }

            &__link-box {
                order: 1;
                flex-grow: 1;

                ul {
                    justify-content: flex-start;
                }
            }
        }
    }
}