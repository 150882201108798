.FitterCardFront {
    display: flex;
    div {
        padding: 8px;

        p {
            font-weight: bold;
        }
    }

    .text {
        max-width: 120px;
    }

    .logo {
        max-width: 80px;
    }
}