.contentBox {
    padding: 20px;
    .congratsImg{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .allButtons{
        display: flex;
        flex-direction: row;
        justify-content: center;

        .buttonContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        
            .button {
                margin: 5px 0;
            }
        }
        
        .finishButton {
            margin-top: 40px;
        }
    }
}

.link {
    color: white
}

@media only screen and (max-width: 420px) {
    .congratsImg{
        display: flex;
        justify-content: center;

        .congratsImgSize{
            width: 250px;
        }
    }
}

@media only screen and (min-width: 426px) {
    .contentBox {
        
        p {
            margin: 30px 0;
            text-align: center;
        }

        .allButtons {

            .buttonContainer {
                margin-top: 5rem;
                flex-direction: row;
                justify-content: center;
        
                .button {
                    margin: 0 5px;
                }
            }
        
            .finishButton {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin: 5rem 0 4rem 0;   
            }
        }
    }
}